import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { TranslateModule }               from '@ngx-translate/core';
import { ScrollbarModule }               from 'ngx-scrollbar';
// MDB
import { MDBBootstrapModulesPro }        from 'ng-uikit-pro-standard';

// Components
import { NxModalComponent }              from './nx-modal.component';
// Directives
import { NxModalContentDirective }       from './nx-modal-content.directive';
// Services
import { NxConfirmModalComponent }       from './nx-confirm-modal/nx-confirm-modal.component';
import { NxModalService }                from './nx-modal.service';

@NgModule({

  imports     : [ CommonModule, MDBBootstrapModulesPro, TranslateModule, ScrollbarModule ],
  exports     : [ NxModalComponent, NxConfirmModalComponent, NxModalContentDirective ],
  declarations: [ NxModalComponent, NxConfirmModalComponent, NxModalContentDirective ],
  providers   : [ NxModalService ]
})
export class NxModalModule {

  static forRoot(): ModuleWithProviders {

    return {
      ngModule : NxModalModule,
      providers: [ ]
    };
  }
}
