import { Injectable }          from '@angular/core';
import { HttpErrorResponse }   from '@angular/common/http';
import { Apollo }              from 'apollo-angular';
import { HttpLink }            from 'apollo-angular-link-http';
import { onError }             from 'apollo-link-error';
import { GraphQLError }        from 'graphql';

// Observable
import { Observable, Subject } from 'rxjs';

// Services
import { NxConfigService }     from '../../services/nx-config.service';
import { NxMessageService }    from '../../../common/components/nx-message/nx-message.service';

@Injectable()
export class NxGraphQLService {

  private httpError = new Subject<HttpErrorResponse>();

  get endpoint()  : string { return `${this.config.endpointGraphQL}` };
  get httpError$(): Observable<HttpErrorResponse> { return this.httpError.asObservable(); }

  constructor(private apollo  : Apollo,
              private httpLink: HttpLink,
              private message : NxMessageService,
              private config  : NxConfigService) { }

  /**
   * 
   * @param error
   */
  sendHttpError(error: HttpErrorResponse): void {

    this.httpError.next(error);
  }

  /**
   * 
   */
  createLink() {

    const http  = this.httpLink.create({ uri: this.endpoint });
    const error = onError(({ graphQLErrors, networkError }) => this.manageError(graphQLErrors, networkError));

    return error.concat(http);
  }

  /**
   * 
   * @param graphQLErrors 
   * @param networkError 
   */
  manageError(graphQLErrors: GraphQLError[], networkError: Error) {
    
    if (graphQLErrors) {

      graphQLErrors.map(({ message, locations, path, extensions }) => {

        this.message.showError(message);
        //console.log(`[GraphQL error]: Message: ${message}, Extensions: ${extensions}, Location: ${locations}, Path: ${path}`)

        if (extensions && extensions.code !== 200)
          this.sendHttpError(new HttpErrorResponse({ error: new Error(message), status: extensions.code }));
      });
    }
    
    if (networkError)
      console.log(`[Network error]: ${networkError}`);
  }
}
