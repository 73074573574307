import { Injectable }                from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';

// Services
import { NxMessageService }          from   '../../common/components/nx-message/nx-message.service';


export interface RequestCacheEntry {

  url     : string;
  response: HttpResponse<any>;
  lastRead: number;
}

// #docregion request-cache
export abstract class RequestCache {

  abstract get(req: HttpRequest<any>): HttpResponse<any> | undefined;
  abstract put(req: HttpRequest<any>, response: HttpResponse<any>): void
}

// #enddocregion request-cache
const maxAge = 6000000; // maximum cache age (ms)

@Injectable()
export class NxRequestCacheService implements RequestCache {

  cachableUrls: string[]                       = [];
  cache       : Map<string, RequestCacheEntry> = new Map<string, RequestCacheEntry>();

  constructor(private messenger: NxMessageService) { }

  isCachable(req: HttpRequest<any>): boolean {

    // cache only get api with no query parameters
    if (req.method !== 'GET' || req.params.keys().length)
      return false;

    return (this.cachableUrls.indexOf(req.url) !== -1);
  }

  addUrl(url: string): void {

    if (!url)
      return;

    this.cachableUrls.push(url);
  }

  clean(url: string): void {

    this.cache.delete(url);
    this.messenger.add(`Removed cached response for "${url}".`);
  }

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {

    const url    = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached)
      return undefined;

    const isExpired = cached.lastRead < (Date.now() - maxAge);
    const expired   = isExpired ? 'expired ' : '';

    this.messenger.add(`Found ${expired}cached response for "${url}".`);

    return isExpired ? undefined : cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {

    const url = req.urlWithParams;

    this.messenger.add(`Caching response from "${url}".`);

    const entry = { url, response, lastRead: Date.now() };

    this.cache.set(url, entry);

    // remove expired cache entries
    const expired = Date.now() - maxAge;

    this.cache.forEach(entry => {

      if (entry.lastRead < expired)
        this.cache.delete(entry.url);
    });

    this.messenger.add(`Request cache size: ${this.cache.size}.`);
  }
}
