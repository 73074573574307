import { Type }                                    from '@angular/core';

// Models
import { NxModalOptions, NxModalComponentOptions } from './nx-modal-options';

export class NxModalItem {

  constructor(public component       : Type<any> | Type<any>[], 
              public componentOptions: NxModalComponentOptions | NxModalComponentOptions[],
              public options         : NxModalOptions = new NxModalOptions()) { }
}