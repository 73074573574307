import { NxObject } from "../../core/utils";

export interface OrganizationInterface {

  _id?      : string;
  code?     : string;
  name?     : string;
  type?     : string;
  parent?   : String;
  parentObj?: OrganizationInterface;
  users?    : any[];
  startDate?: Date;
  endDate?  : Date;
  data?     : NxObject;
}

export class Organization implements OrganizationInterface {
  
  _id?      : string;
  code?     : string;
  name?     : string;
  type?     : string;
  parent?   : String;
  parentObj?: OrganizationInterface;
  users?    : any[];
  startDate?: Date;
  endDate?  : Date;
  data?     : NxObject;

  constructor(obj: OrganizationInterface = {}) {
                      
    this._id       = obj._id;
    this.code      = obj.code;
    this.name      = obj.name;
    this.type      = obj.type;
    this.startDate = obj.startDate; 
    this.endDate   = obj.endDate;
    this.parent    = obj.parent;
    this.parentObj = obj.parentObj || {};
    this.data      = obj.data      || {};
    this.users     = obj.users     || [];
  }

  cleanData() {
  }
}