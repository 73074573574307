import { ArrayCodedItem } from '../../core/graphql/graphql-types';

type UserProfile = {
  firstname: string;
  lastname : string;
};

export interface UserInterface {
  _id?          : string;
  username?     : string;
  password?     : string;
  profile?      : UserProfile;
  email?        : string;
  createdAt?    : Date;
  updatedAt?    : Date;
  organizations?: ArrayCodedItem[];
  active?       : boolean;
  functs?       : string[];
  roles?        : string[];
  deleted?      : boolean;
  deletedAt?    : Date;
}

export class User implements UserInterface {
  _id?          : string;
  username?     : string;
  password?     : string;
  profile?      : UserProfile;
  email?        : string;
  createdAt?    : Date;
  updatedAt?    : Date;
  organizations?: ArrayCodedItem[];
  active?       : boolean;
  functs?       : string[];
  roles?        : string[];
  deleted?      : boolean;
  deletedAt?    : Date;

  get fullName() { return `${this.profile.firstname} ${this.profile.lastname}`};

  constructor(obj?: any) {
    this._id           = (obj && obj._id)           || null;
    this.username      = (obj && obj.username)      || null;
    this.password      = (obj && obj.password)      || null;
    this.profile       = (obj && obj.profile)       || {};
    this.functs        = (obj && obj.functs)        || [];
    this.roles         = (obj && obj.roles)         || [];
    this.active        = (obj && obj.active)        || false;
    this.organizations = (obj && obj.organizations) || []; 
    this.deleted       = (obj && obj.deleted)       || false;
  }

  set firstname(firstname: string) {

    this.profile.firstname = (firstname);
  }

  set lastname(lastname: string) {

    this.profile.lastname = (lastname);
  }

  /**
   * Check if user has at least one of proposed roles
   * 
   * @param roles 
   */
  hasRole(roles: string | string[]): boolean {

    if (!roles || !roles.length)
      return true;

    if (!this.roles)
      return false;

    if (!Array.isArray(roles))
      return (this.roles.indexOf(roles) !== -1);

    if (roles.length === 1)
      return !!this.roles.find(r => { return r === roles[0]; });

    return !!this.roles.find(r => { return roles.indexOf(r) !== -1; });
  }
};
