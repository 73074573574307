import { Injectable }                              from '@angular/core';
import { Router }                                  from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler } from '@angular/common/http';
import { HttpHeaders, HttpRequest, HttpResponse  } from '@angular/common/http';

// Observable
import { Observable }                              from 'rxjs';
import { tap }                                     from 'rxjs/operators';

// Observable operators
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';

// Services
import { AuthService }           from '../services/auth.service';
import { NxRequestCacheService } from '../services/nx-request-cache.service';

@Injectable()
export class NxCachingInterceptor implements HttpInterceptor {

  constructor(private cache: NxRequestCacheService) {}

  /**
   * Interceptor method
   *
   * @returns {Observable<HttpEvent>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // continue if not cachable.
    if (!this.cache.isCachable(req))
      return next.handle(req);

    //console.log('request is cacheable', req);

    const cachedRes = this.cache.get(req);

    return cachedRes ? Observable.of(cachedRes) : this.sendRequest(req, next);
  }

  /**
   * Get server response observable by sending request to `next()`.
   * Will add the response to the cache on the way out.
   *
   * @returns {Observable<HttpEvent>}
   */
  sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // No headers allowed in npm search request
    const noHeaderReq = req.clone({ headers: new HttpHeaders() });

    return next.handle(noHeaderReq).pipe(

      tap(event => {
        // There may be other events besides the response.
        if (event instanceof HttpResponse)
          this.cache.put(req, event); // Update the cache.
      })
    );
  }
}
