import { Component } from '@angular/core';

// Const, Classes, Interfaces
import { CONFIRM_MODAL_ID } from '../models';
import { NxModalComponent } from '../nx-modal.component';

@Component({
  selector   : 'nx-confirm-modal',
  templateUrl: './nx-confirm-modal.component.html',
  styleUrls  : ['./nx-confirm-modal.component.scss']
})
export class NxConfirmModalComponent extends NxModalComponent {

  id: string = CONFIRM_MODAL_ID;

  /**
   * 
   */
  confirm(): void {

    if (this.options && this.options.confirm)
      this.options.confirm.method();

    this.modalComponent.hide();
  }

  /**
   * 
   */
  getMessage(): string {

    if (!this.item || !this.item.componentOptions)
      return '';

    return this.item.componentOptions[0].data;
  }
}
