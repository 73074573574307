import { NgModule }           from '@angular/core';
// Modules
import { NxNavigationModule } from '../common/components/nx-navigation/nx-navigation.module';
// Const, Classes, Interfaces
import { NxMenuItems }        from '../common/components/nx-navigation/nx-menu-item';

const menu: NxMenuItems = [{
  name : 'ns',
  label: 'Shift Planner',
  icon : 'mdi mdi-hotel',
  role : [],
  items: [
    { name: 'plan',       label: 'Planner demo',  role: [], routerLink: '/ns/planning' },
    { name: 'operators',  label: 'Operators Configuration', role: [], routerLink: '/ns/operators' },
    { name: 'tasks',      label: 'Tasks Configuration',     role: [], routerLink: '/ns/tasks' }    
  ]
}];

@NgModule({
  imports  : [ NxNavigationModule.forChild(menu) ],
  exports  : [ NxNavigationModule ],
  providers: []
})
export class NsNavigationModule { }
