import { Injectable, ComponentFactoryResolver, Injector }       from '@angular/core';
import { v4 as uuid }       from 'uuid';

// Const, Classes, Interfaces
import { CONFIRM_MODAL_ID }           from './models';
import { NxModalItem, NxModalButton } from './models';
// Components
import { NxModalComponent }        from './nx-modal.component';
import { NxConfirmModalComponent } from './nx-confirm-modal/nx-confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class NxModalStackService {

  private modals      : Map<string, NxModalComponent>;
  private mainModal   : NxModalComponent;
  private confirmModal: NxModalComponent;
  
  constructor() {

    this.modals = new Map<string, NxModalComponent>();
  }
  
  /**
   * Closes the selected modal by searching for the component
   * 
   * @param { String } modalId The id of the modal to close
   */
  close(modalId?: string): void {

    let modal = this.findModal(modalId);

    if (!modal)
      return;

    modal.close();
  }

  /**
   * Locates the specified modal in the modals array
   * 
   * @param { String } modalId The id of the modal to find
   */
  findModal(modalId: string): NxModalComponent {

    if (this.modals.has(modalId))
      return this.modals.get(modalId);
    
    return this.mainModal;
  }

  /**
   * Opens the specified modal based on the supplied modal id
   * 
   * @param { String } modalId The id of the modal to open
   */
  open(moduleCFR: ComponentFactoryResolver, item: NxModalItem, modalId?: string): void {

    let modal = this.findModal(modalId);

    if (!modal)
      return;
    
    modal.open(moduleCFR, item);
  }

  /**
   * 
   * @param text 
   * @param button 
   */
  openConfirm(moduleCFR: ComponentFactoryResolver, text: string, button: NxModalButton): void {

    if (!this.confirmModal)
      return;
    
    this.confirmModal.open(moduleCFR, new NxModalItem(null, [{ data: text }], { confirm: button, cascading: true }));
  }

  /**
   * Registers all modal components being used on initialization, returns modal id
   * 
   * @param { Object } newModal The new modal to add to the array of available modals
   */
  registerModal(modal: NxModalComponent): void {

    if (!modal || !modal.id)
      return;

    if (modal.id === CONFIRM_MODAL_ID) {

      this.confirmModal = modal;
      return;
    }

    this.modals.set(modal.id, modal);

    if (!this.mainModal)
      this.mainModal = modal;
  }

  /**
   * 
   * @param modal 
   */
  unregisterModal(modal: NxModalComponent): void {

    if (!modal || !modal.id)
      return;

    this.modals.delete(modal.id);

    if (this.mainModal === modal)
      this.mainModal = null;
  }
}
