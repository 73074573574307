import { NgModule }           from '@angular/core';
// Modules
import { NxNavigationModule } from '../common/components/nx-navigation/nx-navigation.module';
// Const, Classes, Interfaces
import { NxMenuItems }        from '../common/components/nx-navigation/nx-menu-item';

const menu: NxMenuItems = [{
  name : 'admin',
  label: 'Administration',
  icon : 'mdi mdi-hexagon-multiple',
  role : ['ADMIN'],
  items: [
    { name: 'users',    label: 'Users',    role: [], routerLink: '/admin/users' },
    //{ name: 'entities', label: 'Entities', role: [], routerLink: '/admin/entities' },
    { name: 'roles',    label: 'Roles',    role: [], routerLink: '/admin/roles' }
  ]
}];

@NgModule({
  imports  : [ NxNavigationModule.forChild(menu) ],
  exports  : [ NxNavigationModule ],
  providers: []
})
export class AdminNavigationModule { }
