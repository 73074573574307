import { InjectionToken } from '@angular/core';

export const MENU_ITEMS = new InjectionToken<NxMenuItem[][]>('MENU_ITEMS');

export type NxMenuItems = NxMenuItem[];

export interface NxMenuItem {
  id?        : string;
  name       : string;
  label      : string;
  icon?      : string;
  styleClass?: string;
  role?      : string[];
  routerLink?: string | string[];
  disabled?  : boolean;
  items?     : NxMenuItem[];
  getPath?() : string;
}
