import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import "rxjs/add/operator/filter";

interface IBreadcrumb {
  label  : string;
  section: boolean;
  url    : string;
}

@Component({
  selector   : 'nx-breadcrumb',
  templateUrl: './nx-breadcrumb.component.html',
  styleUrls  : ['./nx-breadcrumb.component.scss']
})
export class NxBreadcrumbComponent implements OnInit {

  public breadcrumbs: IBreadcrumb[];

  /**
   * @class DetailComponent
   * @constructor
   */
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.breadcrumbs = [];
  }

  /**
   * Let's go!
   *
   * @class DetailComponent
   * @method ngOnInit
   */
  ngOnInit() {
    //subscribe to the NavigationEnd event
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
      //set breadcrumbs
      let root: ActivatedRoute = this.activatedRoute.root;
      this.breadcrumbs = this.getBreadcrumbs(root);
    });
  }

  /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
  private getBreadcrumbs(route: ActivatedRoute, breadcrumbs: IBreadcrumb[]=[]): IBreadcrumb[] {
    
    //get the child routes
    let children: ActivatedRoute[] = route.children;

    //return if there are no more children
    if (children.length === 0)
      return breadcrumbs;
  

    //iterate over each children
    for (let child of children) {

      //verify the custom data property "breadcrumb" is specified on the route
      if (!child.snapshot.data.hasOwnProperty('breadcrumb')) 
        return this.getBreadcrumbs(child, breadcrumbs);
      

      child.snapshot.data.breadcrumb.forEach((elem) => {
        //add breadcrumb
        let breadcrumb: IBreadcrumb = {
          label  : elem.label,
          section: elem.section || undefined,
          url    : elem.routerLink || undefined
        };
        breadcrumbs.push(breadcrumb);
      });

      //recursive
      return this.getBreadcrumbs(child, breadcrumbs);
    }
  }

}
