import { NgModule }                  from '@angular/core';
import { Routes, RouterModule }      from '@angular/router';

// Modules
import { NxNavigationModule }        from './common/components/nx-navigation/nx-navigation.module';
import { BaseNavigationModule }      from './base/base-navigation-module';
import { AdminNavigationModule }     from './admin/admin-navigation-module';
//import { DashboardNavigationModule } from './dashboard/dashboard-navigation-module';
import { NsNavigationModule }        from './night_shifts/ns-navigation.module';
import { CpNavigationModule }        from './capacity_planner/cp-navigation.module';
// Const, Classes
import { NxMenuItem }                from './common/components/nx-navigation/nx-menu-item';
// Services
import { RouteGuardService }         from './core/services/route-guard.service';


// Components
// import { HomeComponent }        from './base/home/home.component';
// import { LoginComponent }       from './base/login/login.component';

const routes: Routes = [

  // { path: '',          component: HomeComponent },
  // { path: 'error/:id', component: ErrorComponent },
  // { path: 'login',     component: LoginComponent },
  { path: '',      loadChildren: './base/base.module#BaseModule' },
 // { path: 'dash',  loadChildren: './dashboard/dashboard.module#DashboardModule', data: { breadcrumb: [{ label: 'Dashboard',           section: true }] } },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule',             data: { breadcrumb: [{ label: 'Admin',               section: true }] } },
  { path: 'ns',    loadChildren: './night_shifts/ns.module#NsModule',            data: { breadcrumb: [{ label: 'Shift Planner',        section: true }] } },
  { path: 'cp',    loadChildren: './capacity_planner/cp.module#CpModule',        data: { breadcrumb: [{ label: 'OR Capacity Planner', section: true }] } },
  { path: '**',    redirectTo: '' }
  //{ path: 'home', loadChildren: 'app/home/home.module#HomeModule', canActivate: [ LoginRouteGuard ] },
  //{ path: 'settings', loadChildren: 'app/tdp/settings/settings.module#SettingsModule', canActivate: [ LoginRouteGuard ] },
  //{ path: 'calh', loadChildren: 'app/tdp/calh/calh.module#CalhModule', canActivate: [ LoginRouteGuard ] },
];

// define here menu from submodules
const menu: NxMenuItem[] = []

@NgModule({
  imports  : [
    RouterModule.forRoot(routes),
    NxNavigationModule.forRoot([/*{ name: 'app', label: 'APP', icon: 'mdi mdi-format-line-weight', role: [], routerLink: [] }*/]),
    BaseNavigationModule,
    AdminNavigationModule,
    //DashboardNavigationModule,
    NsNavigationModule,
    CpNavigationModule
  ],
  exports  : [ RouterModule ],
  providers: []
})
export class AppRoutingModule { }
