import { Injectable, Optional }  from '@angular/core';

export class NxConfig {

  baseUrl   = 'http://54.216.176.33:4000'; //'/'; //window.location.origin;
  demo      = true;
  version   = '0.0.0';
  api       = {
    rest   : 'api/rest',
    graphql: 'api/graphql'
  };
  endpoints = [
    {
      url  :  'http://54.216.176.33:4000', //'/',//window.location.origin,
      users: []
    }
  ];
}

@Injectable()
export class NxConfigService {

  // private user   : User;
  // private baseUrl: string = 'http://localhost:8080/tdp/rest/session';
  private config: NxConfig;

  get isDemo()         : boolean { return this.config.demo; }
  get version()        : string  { return this.config.version; }
  get baseUrl()        : string  { return this.config.baseUrl; }
  get endpointRest()   : string  { return `${this.config.baseUrl}/${this.config.api.rest}`; }
  get endpointGraphQL(): string  { return `${this.config.baseUrl}/${this.config.api.graphql}`; }

  constructor(@Optional() config: NxConfig) {

    console.log('NxConfigService', config);
    this.config = config || new NxConfig();
  }

  setBaseUrlByUser(username: string) {

    if (!username)
      return;

    for (var i = 0; i < this.config.endpoints.length; i++) {

      if (this.config.endpoints[i].users.indexOf(username) !== -1) {

        this.config.baseUrl = this.config.endpoints[i].url;
        break;
      }
    }
  }
}
