import { Injectable }               from '@angular/core';
import { HttpHeaders, HttpClient }  from '@angular/common/http';
// Observable
import { Observable }               from 'rxjs';
import { of }                       from 'rxjs';
import { catchError, map, tap }     from 'rxjs/operators';

// Services
import { NxCommonService }          from '../services/nx-common.service';
import { NxMessageService }         from '../../common/components/nx-message/nx-message.service';

@Injectable()
export class AbstractHttpService<T> {

  protected name       : string = '';
  protected combo      : T[];
  protected isCacheable: boolean = false;

  constructor(protected http           : HttpClient,
              protected messageService : NxMessageService,
              protected commonService  : NxCommonService) { }

  setCacheable() {

    this.isCacheable = true;
    this.commonService.addCacheableUrl(this.getUrl());
  }

  getCombo(forceReload: boolean = false): T[] {

    if (!forceReload && this.combo.length)
      return this.combo;

    this.list().subscribe(data => {
      data.forEach(d => this.combo.push(d));
      this.combo = this.combo;
    });

    return this.combo;
  }

  getUrl(suffix?: string) {

    return `${this.commonService.getBaseUrl()}/${this.name && this.name}${suffix ? suffix : ''}`;
  }

  list(params?: any): Observable<T[]> {

    return this.http.get<T[]>(`${this.getUrl()}`, { params: params || {} }).pipe(
      tap(res => this.log(`fetched ${this.name}`)),
      catchError(this.handleError(`get ${this.name}`, []))
    );
  }

  get(id: number): Observable<T> {

    return this.http.get<T>(this.getUrl(`/${id}`), {}).pipe(
      tap(res => this.log(`fetched ${this.name} id=${id}`)),
      catchError(this.handleError<T>(`get ${this.name} id = ${id}`))
    );
  }

  insert(entity: T) : Promise<T> {
    //new HttpHeaders().set('Authorization', 'my-auth-token'),
    let res = this.http.post<T>(this.getUrl(), entity, {})
    // .pipe(
    //   tap(res => this.log(`insert ${this.name}`)),
    //   catchError(this.handleError<T>(`get ${this.name}`))
    // )
    .toPromise();

    res.then(
      (res) => {

        this.messageService.showSuccess('Elemento inserito');

        if (this.isCacheable)
          this.commonService.cleanCacheableUrl(this.getUrl());
      },
      (error) => {

        console.log(error);
        this.messageService.showError('Si è verificato un errore, il dato non è stato inserito');
      }
    );
    return res;
  }

  update(id: number, entity: T) : Promise<T> {

    let res = this.http.put<T>(this.getUrl(`/${id}`), entity, {}).toPromise();
    res.then(
      (data) => {

        this.messageService.showSuccess('Elemento salvato');

        if (this.isCacheable)
          this.commonService.cleanCacheableUrl(this.getUrl());
      },
      (error) => {

        console.log(error);
        this.messageService.showError('Si è verificato un errore, il dato non è stato salvato');
      }
    );
    return res;
  }

  remove<T>(id: number): Promise<T> {

    let res = this.http.delete<T>(this.getUrl(`/${id}`), {}).toPromise();
    res.then(
      (data) => {

        this.messageService.showSuccess('Elemento eliminato');

        if (this.isCacheable)
          this.commonService.cleanCacheableUrl(this.getUrl());
      },
      (error) => {

        console.log(error);
        this.messageService.showError('Si è verificato un errore, il dato non è stato eliminato');
      }
    );
    return res;
  }

  /**
   * Log a message with the Nx MessageService
   */
  private log(message: string) {

    // console.log(message);
    this.messageService.add(message);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
