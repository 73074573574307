import { NgModule }           from '@angular/core';
// Modules
import { NxNavigationModule } from '../common/components/nx-navigation/nx-navigation.module';
// Const, Classes, Interfaces
import { NxMenuItems }        from '../common/components/nx-navigation/nx-menu-item';

const menu: NxMenuItems = [{
  name : 'cp',
  label: 'OR Capacity Planner',
  icon : 'mdi mdi-heart-pulse',
  role : [],
  items: [
    { name: 'planning',      label: 'Planner Demo',      role: [], routerLink: '/cp/planning' },
    { name: 'registrations', label: 'Registrations Configuration', role: [], routerLink: '/cp/registrations' }
  ]
}];

@NgModule({
  imports  : [ NxNavigationModule.forChild(menu) ],
  exports  : [ NxNavigationModule ],
  providers: []
})
export class CpNavigationModule { }
