import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router }                                             from '@angular/router';

import * as moment from 'moment';

// Const, Classes, Interfaces
import { User }        from '../../../admin/models';
// Services
import { AuthService } from '../../services/auth.service';

@Component({
  selector   : 'nx-header',
  templateUrl: './nx-header.component.html',
  styleUrls  : ['./nx-header.component.scss']
})
export class NxHeaderComponent implements OnInit {

  @Output() openMenu: EventEmitter<any> = new EventEmitter();

  isOpen: boolean;
  user  : User;

  get isLoggedIn() { return this.auth.isLoggedIn(); }

  constructor(private router: Router, private auth: AuthService) {

    //this.auth.checkSession();
  }

  ngOnInit() {

    this.auth.getUser().subscribe(data => this.user = data);
  }

  /**
   * 
   */
  getCurrentDate(): string {

    return moment().locale('it').format('dddd DD MMMM YYYY');
  }

  /**
   * 
   */
  getUser(): User {

    return this.user;
  }

  /**
   * 
   */
  openSideMenu(): void {

    this.isOpen = !this.isOpen;
    this.openMenu.emit(this.isOpen);
  }

  /**
   * 
   */
  isSnapped(): boolean {

    return this.isOpen;
  }

  /**
   * 
   */
  logout(): void {

    this.auth.logout();
    // this.confirmationService.confirm({
    //   message: 'Sei sicuro di voler uscire?',
    //   accept : () => {

    //     this.auth.logout();
    //   }
    // });
  }
}
