import { Injectable, Optional, Injector, InjectionToken } from '@angular/core';
import { Router }                                         from '@angular/router';
// Observable
import { BehaviorSubject, Observable }         from 'rxjs';
// Const, Classes, Interfaces
import { User }                                from '../../../admin/models';
import { NxMenuItem, NxMenuItems, MENU_ITEMS } from './nx-menu-item';
// Services
import { AuthService }                         from '../../../core/services/auth.service';

@Injectable()
export class NxNavigationService {

  // private user: User;
  private menu     : NxMenuItem;
  private menuItems: BehaviorSubject<NxMenuItem[]> = new BehaviorSubject<NxMenuItem[]>([]);

  constructor(injector: Injector, private router: Router, private auth: AuthService) {

    this.menu = {
      id   : 'root',
      name : 'root',
      label: '',
      items: []
    };

    let menuConfig = injector.get(MENU_ITEMS);

    this.setupMenu(menuConfig);

    this.auth.getUser().subscribe(data => this.menuItems.next(this.setMenu(data)));
  }

  getPath(role: string, path: string): string {

    return path;
  }

  getMenu(): Observable<any[]> {

    return this.menuItems.asObservable();
  }

  setMenu(user: User): NxMenuItem[] {

    return this.menu.items;
  }

  sendToHome(): void {

    this.router.navigate(['/']);
  }

  sendToLogin(): void {

    this.auth.cleanSession();
    // this.router.navigate(['/login']);
  }

  sendToError(code): void {

    this.router.navigate(['/error/' + code]);
  }

  /**
   * Concat all menu configuration set up by all navigation modules
   * to create the full menu structure
   * 
   * @param config 
   */
  private setupMenu(config: NxMenuItems[]): void {

    if (!config)
      return;

    this.menu.items = config.reduce((accumulator, items) => accumulator.concat(items), []);
  }
}
