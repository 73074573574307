import { NxObject } from '../../../../core/utils';

export const CONFIRM_MODAL_ID = 'nxConfirmModal';

export const NX_MODAL_STYLE: NxObject = {
  info   : 'modal-notify modal-info',
  success: 'modal-notify modal-success',
  warning: 'modal-notify modal-warning',
  danger : 'modal-notify modal-danger'
}
