import { NgModule, ModuleWithProviders, InjectionToken, Optional, SkipSelf } from '@angular/core';
import { CommonModule }                                                      from '@angular/common';
// Const, Classes, Interfaces
import { MENU_ITEMS }              from './nx-menu-item';
import { NxMenuItem, NxMenuItems } from './nx-menu-item';
// Services
import { NxNavigationService }     from './nx-navigation.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: []
})
export class NxNavigationModule {

  constructor(@Optional() @SkipSelf() parentModule: NxNavigationModule) { }

  /**
   * 
   * @param menuItems
   */
  static forRoot(menuItems: NxMenuItems): ModuleWithProviders {

    return {
      ngModule : NxNavigationModule,
      providers: [
        NxNavigationService,
        { provide: MENU_ITEMS, multi: true, useValue: menuItems }
      ]
    };
  }

  /**
   * 
   * @param menuItems 
   */
  static forChild(menuItems: NxMenuItems): ModuleWithProviders {

    return {
      ngModule : NxNavigationModule,
      providers: [{ provide: MENU_ITEMS, multi: true, useValue: menuItems }]
    };
  }
}
