import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[nx-modal-host]'
})
export class NxModalContentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
