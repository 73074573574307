import { NxMessageType } from './nx-message';

export class NxMessageOptions {

  closeButton?  : boolean;
  progressBar?  : boolean;
  positionClass?: string = NxMessageType.bottomCenter;
  timeOut?      : number;
  maxOpened?    : number;

  constructor(options: NxMessageOptions = {}) {
	  
    this.closeButton   = options && options.closeButton   || false;
    this.progressBar   = options && options.progressBar   || false;
    this.positionClass = options && options.positionClass || NxMessageType.bottomCenter;
    this.timeOut       = options && options.timeOut       || 5000;
    this.maxOpened     = options && options.maxOpened     || 1;
    
//    this.color       = options && options.color       || 'auto';
//    this.background  = options && options.background  || '#224562';
//    this.customClass = options && options.customClass || 'slideInUp animated';
//    this.accent      = options && options.accent      || '#33b5e5';
  }
}