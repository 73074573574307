import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule }                                      from '@angular/common';
import { RouterModule }                                      from '@angular/router';
import { HttpClientModule }                                  from '@angular/common/http';
import { ApolloModule }                                      from 'apollo-angular';
import { HttpLinkModule }                                    from 'apollo-angular-link-http';
// MDB
import { MDBBootstrapModulesPro, ToastModule }               from 'ng-uikit-pro-standard';
import { MDBSpinningPreloader }                              from 'ng-uikit-pro-standard';
//import { MDBSpinningPreloader }                            from 'ng-mdb-pro';

// Interceptor
import { httpInterceptorProviders }  from './http-interceptors';
// Services
import { AbstractHttpService }       from './services/abstract-http.service';
import { AuthService }               from './services/auth.service';
import { ComboService }              from './services/combo.service';
import { NxCommonService }           from './services/nx-common.service';
import { NxConfig, NxConfigService } from './services/nx-config.service';
import { NxCryptService }            from './services/nx-crypt.service';
import { NxRequestCacheService }     from './services/nx-request-cache.service';
import { PdfService }                from './services/pdf.service';
import { RouteGuardService }         from './services/route-guard.service';
// import { NxUploaderService }         from '../shared/components/nx-uploader';

// Modules
import { GraphQLModule }             from './graphql/graphql.module';
import { NxModalModule }             from '../common/components/nx-modal/nx-modal.module';
import { NxMessageModule }           from '../common/components/nx-message/nx-message.module';
// Components
import { NxHeaderComponent }         from './components/nx-header/nx-header.component';
import { NxSidenavComponent }        from './components/nx-sidenav/nx-sidenav.component';
// Directives
import { NxPreventDropDirective }    from '../common/directives/nx-prevent-drop.directive';
import { NxBreadcrumbComponent } from './components/nx-breadcrumb/nx-breadcrumb.component';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule, // provides HttpClient for HttpLink
    //ApolloModule,
    HttpLinkModule,
    RouterModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    GraphQLModule, // Apollo,
    NxModalModule.forRoot(),
    NxMessageModule.forRoot()
  ],
  declarations: [ NxSidenavComponent, NxHeaderComponent, NxPreventDropDirective, NxBreadcrumbComponent ],
  exports     : [ NxSidenavComponent, NxHeaderComponent, NxPreventDropDirective, NxModalModule, NxMessageModule ],
  providers   : [ RouteGuardService/*,  MDBSpinningPreloader */]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

    if (parentModule)
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
  }

  /**
   * 
   * @param config 
   */
  static forRoot(config: NxConfig): ModuleWithProviders {

    return {
      ngModule : CoreModule,
      providers: [
        httpInterceptorProviders,
        { provide: NxConfig, useValue: config },
        NxCommonService,
        ComboService,
        AbstractHttpService,
        AuthService,
        NxConfigService,
        NxCryptService,
        NxRequestCacheService,
        MDBSpinningPreloader
      ]
    };
  }
}
