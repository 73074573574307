import { Component }   from '@angular/core';

// Services
import { AuthService } from './core/services/auth.service';

@Component({
  selector   : 'app-root',
  templateUrl: './app.component.html',
  styleUrls  : ['./app.component.scss']
})
export class AppComponent {

  constructor(private authService: AuthService) {

    this.authService.checkSession();
  }
}
