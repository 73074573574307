import { ModalOptions }                     from 'ng-uikit-pro-standard';

// Models
import { NxModalTabOptions, NxModalButton } from './nx-modal.types';
import { NxModalType }                      from './nx-modal.enums';

export interface NxModalOptionsInterface {
  title?      : string;
  titleIcon?  : string;
  type?       : string;
  style?      : string;
  modalClass? : string;
  headerClass?: string;
  bodyClass?  : string;
  cascading?  : boolean;
  tabMode?    : boolean;
  showHeader? : boolean;
  showClose?  : boolean;
  showFooter? : boolean;
  modalConfig?: ModalOptions;
  tabs?       : NxModalTabOptions[];
  cancel?     : NxModalButton;
  confirm?    : NxModalButton;
  buttons?    : NxModalButton[];
}

export class NxModalOptions implements NxModalOptionsInterface {
  title?      : string;
  titleIcon?  : string;
  type?       : string  = NxModalType.CENTRAL_SM;
  style?      : string  = '';
  modalClass? : string;
  headerClass?: string  = 'gradient-45deg-surgiq-pink white-text';
  bodyClass?  : string;
  cascading?  : boolean = true;
  tabMode?    : boolean = false;
  showHeader? : boolean = true;
  showClose?  : boolean = true;
  showFooter? : boolean = true;
  modalConfig?: ModalOptions;
  tabs?       : NxModalTabOptions[];
  cancel?     : NxModalButton;
  confirm?    : NxModalButton;
  buttons?    : NxModalButton[];

  constructor(options: NxModalOptionsInterface = {}) {

    this.title       = options.title;
    this.titleIcon   = options.titleIcon;
    this.type        = options.type        || NxModalType.CENTRAL_LG;
    this.style       = options.style;
    this.modalClass  = options.modalClass  || '';
    this.headerClass = options.headerClass || 'gradient-45deg-surgiq-pink white-text';
    this.bodyClass   = options.bodyClass;
    this.tabMode     = options.tabMode;
    this.modalConfig = options.modalConfig || { ignoreBackdropClick: true, backdrop: 'static' };
    this.tabs        = options.tabs;
    this.cancel      = options.cancel;
    this.confirm     = options.confirm;
    this.buttons     = options.buttons;

    if (options.cascading === false)
      this.cascading = false;
    if (options.showHeader === false)
      this.showHeader = false;
    if (options.showFooter === false)
      this.showFooter = false;
    if (options.showClose === false)
      this.showClose = false;

    if (options.type === NxModalType.FRAME_BOTTOM || options.type === NxModalType.FRAME_TOP ||
        options.type === NxModalType.FLUID_BOTTOM || options.type === NxModalType.FLUID_TOP ||
        options.type === NxModalType.FLUID_RIGHT  || options.type === NxModalType.FLUID_LEFT ||
        options.type === NxModalType.FULL_PAGE) {

      this.cascading = false;
    }

    if (options.style)
      this.headerClass = '';
  }
}

export class NxModalComponentOptions {
  data?   : any;
  options?: any;
}
