import { Injectable, Optional }  from '@angular/core';

// Services
import { NxConfigService }       from './nx-config.service';
import { NxRequestCacheService } from './nx-request-cache.service';

@Injectable()
export class NxCommonService {

  regex = {
    number  : new RegExp(/^\+?(0|[1-9]\d*)$/),
    decimal : new RegExp(/^\+?(0|[1-9]\d*)(\.\d*)?$/),
    numbers : new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g),
    digits  : new RegExp(/^\[0-9]*$/),
    currency: new RegExp(/^(\$?\d{1,3}(,?\d{3})?(\.\d\d?)?|\(\$?\d{1,3}(,?\d{3})?(\.\d\d?)?\))$/),
    // decimal: new RegExp(/^\+?(0|[1-9]\d*)(\.\d{1,2})?$/),
    year    : new RegExp(/^(19|20)\d{2}$/),
    date    : new RegExp(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/),
    phone   : new RegExp(/^[0-9]{2,15}$/),
    email   : new RegExp(/^.+@.+\..+$/),///^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    taxCode : new RegExp(/^[a-zA-Z]{6}[a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{2}[a-zA-Z][a-zA-Z0-9]{3}[a-zA-Z]$/)
  }

  constructor(private config: NxConfigService, private cacheService: NxRequestCacheService) {}

  isDemo(): boolean {

    return this.config.isDemo;
  }

  getVersion(): string {

    return this.config.version;
  }

  getBaseUrl(): string {

    return this.config.baseUrl;
  }

  addCacheableUrl(url: string) {

    this.cacheService.addUrl(url);
  }

  cleanCacheableUrl(url: string) {

    this.cacheService.clean(url);
  }

  testEmail(value: string): boolean {

    return this.regex.email.test(value);
  }
  
  testTaxCode(value: string): boolean {

    return this.regex.taxCode.test(value);
  }

  testField(type: string, value: string): boolean {

    if (!type)
      return true;

    return this.regex[type] && this.regex[type].test(value);
  }
}
