import { Injectable }    from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { NxMessageOptions, NxMessageType } from './models';

@Injectable({
  providedIn: 'root'
})
export class NxMessageService {

  snackbarOptions: NxMessageOptions;
  messages       : string[] = [];

  private toastOptions: NxMessageOptions = { progressBar: false, closeButton: true, positionClass: NxMessageType.bottomCenter, maxOpened: 1 };

  constructor(private toastrService: ToastrService) { }

  /**
   * 
   * @param message 
   */
  add(message: string): void {

    this.messages.push(message);
  }

  /**
   * 
   * @param options 
   */
  getOptions(options: NxMessageOptions): NxMessageOptions {

    if (!options)
      return this.toastOptions;

    return Object.assign({}, this.toastOptions, options);
  }
  
  /**
   * 
   */
  showDefault(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.show(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   */
  showInfo(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.info(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
  * 
  */
  showSuccess(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.success(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   */
  showWarning(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.warning(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   */
  showError(message: string, title?: string, options?: NxMessageOptions): void {

    this.toastrService.error(message, title, this.getOptions(options));
    this.add(message);
  }

  /**
   * 
   */
  private clear(): void {

    this.messages = [];
  }

  /**
   * 
   * @param data 
   */
  private showMessage(data: any): void {

    this.add(data.detail);
  }
}
