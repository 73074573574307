import { Injectable, ComponentFactoryResolver, Injector, Type }       from '@angular/core';

// Const, Classes, Interfaces
import { NxModalItem, NxModalButton, NxModalComponentOptions, NxModalOptions } from './models';
// Services
import { NxModalStackService }        from './nx-modal-stack.service';

@Injectable({
  providedIn: 'root'
})
export class NxModalService {
  
  constructor(private moduleCFR : ComponentFactoryResolver, 
              private injector  : Injector, 
              private modalStack: NxModalStackService) { }
  
  /**
   * Closes the selected modal by searching for the component
   * 
   * @param { String } modalId The id of the modal to close
   */
  close(modalId?: string): void {

    this.modalStack.close(modalId);
  }

  /**
   * Opens the specified modal based on the supplied modal id
   * 
   * @param { String } modalId The id of the modal to open
   */
  open(item: NxModalItem, modalId?: string): void {

    this.modalStack.open(this.moduleCFR, item, modalId);
  }

  /**
   * Opens the specified modal based on the supplied modal id
   * Accepts options to build NxModalItem
   * 
   * @param param0 
   * @param modalId 
   */
  openModal(component: Type<any> | Type<any>[] , componentOptions: NxModalComponentOptions | NxModalComponentOptions[], modalOptions: NxModalOptions, modalId?: string): void {

    this.modalStack.open(this.moduleCFR, new NxModalItem(component, componentOptions, modalOptions), modalId);
  }

  /**
   * 
   * @param text 
   * @param button 
   */
  openConfirm(text: string, button: NxModalButton): void {

    this.modalStack.openConfirm(this.moduleCFR, text, button);
  }
}
