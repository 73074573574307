import { NgModule }             from '@angular/core';
import { HttpClientModule }     from '@angular/common/http';
// Apollo
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule }       from 'apollo-angular-link-http';
import { InMemoryCache }        from 'apollo-cache-inmemory';

// Services
import { NxGraphQLService }     from './services/nx-graphql.service';

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule
  ],
  providers: [NxGraphQLService]
})
export class GraphQLModule {
  
  constructor(private apollo: Apollo, private graphql: NxGraphQLService) {

    // create Apollo
    apollo.create({
      link : this.graphql.createLink(),
      cache: new InMemoryCache()
    });
  }
}
// new InMemoryCache({dataIdFromObject(result: any) {

//     if (result._id && result.__typename) {
//       const dataId = result.__typename + result._id;
//       return dataId;
//     }

//     return null;
//   }
// })