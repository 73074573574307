import { NgModule }           from '@angular/core';
// Modules
import { NxNavigationModule } from '../common/components/nx-navigation/nx-navigation.module';
// Const, Classes, Interfaces
import { NxMenuItems }        from '../common/components/nx-navigation/nx-menu-item';

const menu: NxMenuItems = [];

@NgModule({
  imports  : [ NxNavigationModule.forChild(menu) ],
  exports  : [ NxNavigationModule ],
  providers: []
})
export class BaseNavigationModule { }
