import { HTTP_INTERCEPTORS }    from '@angular/common/http';

import { NxAuthInterceptor }    from './nx-auth-interceptor';
import { NxCachingInterceptor } from './nx-caching-interceptor';

/**
 * Http interceptor providers in outside-in order
 * The order of execution is the inverse: A, B, C are exectued as C -> B -> A
 */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: NxCachingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: NxAuthInterceptor,    multi: true }
];
