import { Injectable } from '@angular/core';

export interface ComboItem {

  id?        : string;
  label      : string;
  labelShort?: string;
  value?     : any;
  icon?      : string;
  className? : string;
}

@Injectable()
export class ComboService {

  constructor() {}

  yesNo(): ComboItem[] {

    return [
      { id: 'YES', label: 'Si' },
      { id: 'NO',  label: 'No' }
    ];// as ComboItem[];
  }

  gender(): ComboItem[] {

    return [
      { id: 'M', label: 'Maschio', icon: '', className: 'male'   },
      { id: 'F', label: 'Femmina', icon: '', className: 'female' }
    ];// as ComboItem[];
  }

  calendarLocale(): any {

    return {
      firstDayOfWeek : 1,
      dayNames       : ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      dayNamesShort  : ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
      dayNamesMin    : ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa'],
      monthNames     : ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
      monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu','Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
      today          : 'Oggi',
      clear          : 'Pulisci'
    };
  }

  weekdays(): ComboItem[] {

    return [
      { id: 'LU', labelShort: 'Lun', label: 'Lunedì',    value: 0 },
      { id: 'MA', labelShort: 'Mar', label: 'Martedì',   value: 1 },
      { id: 'ME', labelShort: 'Mer', label: 'Mercoledì', value: 2 },
      { id: 'GI', labelShort: 'Gio', label: 'Giovedì',   value: 3 },
      { id: 'VE', labelShort: 'Ven', label: 'Venerdì',   value: 4 },
      { id: 'SA', labelShort: 'Sab', label: 'Sabato',    value: 5 }
    ];
  }
}
