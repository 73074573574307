import { NgModule, ModuleWithProviders }   from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { ToastrModule }                    from 'ngx-toastr';

// Components
import { NxMessageComponent }              from './nx-message.component';
// Services
import { NxMessageService }                from './nx-message.service';

@NgModule({
  imports: [
    CommonModule,
    ToastrModule.forRoot({
      maxOpened     : 1,  
      toastComponent: NxMessageComponent
    })
  ],
  declarations   : [ NxMessageComponent ],
  exports        : [ NxMessageComponent ],
  providers      : [ NxMessageService ],
  entryComponents: [ NxMessageComponent ]
})
export class NxMessageModule { 

  static forRoot(): ModuleWithProviders {

    return {
      ngModule : NxMessageModule,
      providers: []
    };
  }
}
