export enum NxModalType {
  CENTRAL_SM        = 'modal-sm',
  CENTRAL_MD        = 'modal-md',
  CENTRAL_LG        = 'modal-lg',
  CENTRAL_FLUID     = 'modal-fluid',
  SIDE_TOP_RIGHT    = 'modal-side modal-top-right',
  SIDE_TOP_LEFT     = 'modal-side modal-top-left',
  SIDE_BOTTOM_RIGHT = 'modal-side modal-bottom-right',
  SIDE_BOTTOM_LEFT  = 'modal-side modal-bottom-left',
  FRAME_BOTTOM      = 'modal-frame modal-bottom',
  FRAME_TOP         = 'modal-frame modal-bottom-top',
  FLUID_BOTTOM      = 'modal-full-height modal-bottom',
  FLUID_TOP         = 'modal-full-height modal-top',
  FLUID_RIGHT       = 'modal-full-height modal-right',
  FLUID_LEFT        = 'modal-full-height modal-left',
  FULL_PAGE         = 'modal-full-height modal-cover'
}

// Modal type
/*
<div class="modal-dialog modal-sm" role="document">

CENTRAL
.modal-sm Small Modal
.modal-lg Large Modal
.modal-fluid Full Width Modal

SIDE
.modal-side + .modal-top-right Top Right
.modal-side + .modal-top-left Top Left
.modal-side + .modal-bottom-right Bottom Right
.modal-side + .modal-bottom-right Bottom Left

FRAME
.modal-frame + .modal-bottom Bottom
.modal-frame + .modal-top Top

FLUID
.modal-full-height + .modal-right Right
.modal-full-height + .modal-left Left
.modal-full-height + .modal-bottom Bottom
.modal-full-height + .modal-top Top
*/

// Modal Style

/*
<div class="modal-dialog modal-notify modal-success" role="document">

By using our predefined modal styles you can add some emotional weight to your information, ranging from a simple warning to critical system failure or from an operation success to a neutral information.

It requires 2 simple steps

Step 1: Add class .modal-notify to the .modal-dialog div.
Step 2: Add class .success, .info, .danger or .warning to the .modal-dialog div.
*/