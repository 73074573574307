import { Component, OnInit, Input, ViewChild, Output } from '@angular/core';
import { Router, ActivatedRoute }                      from '@angular/router';
import { SidenavComponent }                            from 'ng-uikit-pro-standard';
import { Observable }                                  from 'rxjs';

// Const, Classes, Interfaces
import { User }                from '../../../admin/models';
import { NxMenuItem }          from '../../../common/components/nx-navigation/nx-menu-item';
// Services
import { AuthService }         from '../../services/auth.service';
import { NxNavigationService } from '../../../common/components/nx-navigation/nx-navigation.service';
import { NxMessageService }    from '../../../common/components/nx-message/nx-message.service';

@Component({
  selector     : 'nx-sidenav',
  templateUrl  : './nx-sidenav.component.html',
  styleUrls    : ['./nx-sidenav.component.scss']
  //,encapsulation: ViewEncapsulation.None
})
export class NxSidenavComponent implements OnInit {

  @Input() openMenu: boolean;
  @Input() fixed   : boolean = true;

  get items(): Observable<NxMenuItem[]> { 

    return this.navigation.getMenu();
  }

  @ViewChild(SidenavComponent)
  private sidenavComponent: SidenavComponent;

  constructor(private router    : Router,
              private auth      : AuthService,
              private navigation: NxNavigationService,
              private message   : NxMessageService) {}

  ngOnInit() {
	  
    this.navigation.getMenu().subscribe(console.log);
  }

  /**
   * 
   */
  notAvailable(): void {

    this.message.showWarning('Funzione non disponibile');
  }

  /**
   * 
   */
  getItemRoute(item: NxMenuItem): string | string[] {

    if (this.hasItems(item))
      return null;
      
    return item.routerLink;
  }

  /**
   * 
   */
  hasItems(item: NxMenuItem): boolean {

    return !!(item.items && item.items.length);
  }

  /**
   * 
   */
  isLoggedIn(): Observable<boolean> {

    return this.auth.isLoggedIn();
  }

  /**
   * 
   */
  show(): void {

    this.sidenavComponent.toggle();
  }
}
